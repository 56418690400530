import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '~/components/layout'
import Content from '~/components/new/content'
import { H1, H2, P } from '~/components/new/typography'
import LocationsMapSearch from '~/components/locations-map/locations-map-search'
import Spacer from '~/components/new/spacer'
import { LocationDirectoryGrid } from '~/components/location-directory-grid'
import { Seo } from '~/components/seo'
import { BreadcrumbsSection } from '~/components/new/breadcrumbs/breadcrumbs-section'
import { BreadcrumbsNav } from '~/components/new/breadcrumbs/breadcrumbs-nav'

import convertArrayToInlineList from 'utils/convert-array-to-inline-list'

const breadcrumbs = [
  {
    name: 'Hutson Inc',
    displayName: 'Home',
    link: '/',
  },
  {
    name: 'Locations',
    link: '/locations/',
  },
]

/**
 * @param {import('gatsby').PageProps<LocationsPageDataProps>} props
 */
const LocationsPage = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      locationStats: { states: allSanityLocationStates, totalCount: locationCount },
    },
  } = props

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList)

  return (
    <Layout kind='white'>
      <Seo
        title='Locations'
        description={`Hutson Inc has ${locationCount} stores conveniently located across ${statesListString}. Find a Hutson store near you for great products, service, and parts.`}
        breadcrumbs={breadcrumbs}
      />
      <PageContent>
        <BreadcrumbsSection>
          <BreadcrumbsNav items={breadcrumbs} />
        </BreadcrumbsSection>
        <Content kind='full' className='page-top-content'>
          <H1>Locations</H1>
          <P className='page-subtitle'>
            {locationCount} locations across {statesListString}
          </P>
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
        <Content kind='full'>
          <H2>Location Directory</H2>
          <Spacer size='m' />
          <LocationDirectoryGrid />
        </Content>
      </PageContent>
    </Layout>
  )
}

const PageContent = styled.div`
  .page-top-content {
    padding: 24px 0 36px;
  }

  @media (min-width: 900px) {
    .page-top-content {
      padding: 48px 64px 64px;
    }
  }

  .page-subtitle {
    margin: 0;
  }
`

/**
 * @typedef LocationsPageDataProps
 * @property {Object} allSanityLocation
 * @property {Object[]} allSanityLocation.nodes
 * @property {string} allSanityLocation.nodes[].address
 * @property {string} allSanityLocation.nodes[].city
 * @property {Object} allSanityLocation.nodes[].geolocation
 * @property {number} allSanityLocation.nodes[].geolocation.lat
 * @property {number} allSanityLocation.nodes[].geolocation.lng
 * @property {string} allSanityLocation.nodes[].directionsLink
 * @property {Object} allSanityLocation.nodes[].hours
 * @property {string} allSanityLocation.nodes[].hours.sunday
 * @property {string} allSanityLocation.nodes[].hours.monday
 * @property {string} allSanityLocation.nodes[].hours.tuesday
 * @property {string} allSanityLocation.nodes[].hours.wednesday
 * @property {string} allSanityLocation.nodes[].hours.thursday
 * @property {string} allSanityLocation.nodes[].hours.friday
 * @property {string} allSanityLocation.nodes[].hours.saturday
 * @property {number} allSanityLocation.nodes[].locationNumber
 * @property {string} allSanityLocation.nodes[].phoneNumber
 * @property {string} allSanityLocation.nodes[].slug
 * @property {Object[]} allSanityLocation.nodes[].specialHours
 * @property {string} allSanityLocation.nodes[].specialHours[].date
 * @property {string} allSanityLocation.nodes[].specialHours[].hours
 * @property {string} allSanityLocation.nodes[].specialHours[].title
 * @property {string} allSanityLocation.nodes[].state
 * @property {('open'|'temporarily-closed'|'permanently-closed')} allSanityLocation.nodes[].status
 * @property {string} allSanityLocation.nodes[].title
 * @property {number} allSanityLocation.nodes[].zip
 * @property {Object} locationStats
 * @property {Object[]} locationStats.states
 * @property {string} locationStats.states[].fieldValue
 * @property {number} locationStats.states[].totalCount
 * @property {number} locationStats.totalCount
 */

export const pageQuery = graphql`
  query locationsPage {
    allSanityLocation(sort: { fields: title, order: ASC }) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
    locationStats: allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      states: group(field: state) {
        totalCount
        fieldValue
      }
      totalCount
    }
  }
`

export default LocationsPage
